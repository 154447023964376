import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Button } from 'primereact/button';
import { useResize } from '@libs/utils';
import { Lock } from '@shared';
import { type Product } from '@libs/types';
import { useDispatch } from 'react-redux';
import { type AppDispatch } from '@store/store';
import { downloadFileThunk } from '@store/store/thunk/attachment.thunk';
import { unwrapResult } from '@reduxjs/toolkit';
import { useRoutes } from '@libs/utils/hooks/useRoutes';

import styles from './styles.module.scss';
import { ProductMnemoCodes } from './types';

type Props = {
    data: Product;
    isProductPage?: boolean;
    imagePosition?: 'left' | 'right';
};

export const ProductCard = ({ data, isProductPage = false, imagePosition }: Props) => {
    const dispatch = useDispatch<AppDispatch>();
    const { isScreenSm, isScreenMd, isScreenLg, isScreenXl } = useResize();
    const [flexDirection, setFlexDirection] = useState<
        'flex-row' | 'flex-row-reverse' | 'flex-column' | 'flex-column-reverse'
    >('flex-row-reverse');
    const [logo, setLogo] = useState<string>();
    const [image, setImage] = useState<string>();

    const productRoute = useRoutes();

    useEffect(() => {
        if (data.cartImage) {
            dispatch(downloadFileThunk({ bucket: data.cartImage.bucket, ticket: data.cartImage.fileId }))
                .then((resp) => {
                    const result = unwrapResult(resp);
                    setLogo(result);
                })
                .catch((e) => console.error(e));
        }

        if (data.image) {
            dispatch(downloadFileThunk({ bucket: data.image.bucket, ticket: data.image.fileId }))
                .then((resp) => {
                    const result = unwrapResult(resp);
                    setImage(result);
                })
                .catch((e) => console.error(e));
        }
    }, []);

    useEffect(() => {
        if (isScreenXl || isScreenLg) {
            setFlexDirection(isProductPage && imagePosition === 'left' ? 'flex-row' : 'flex-row-reverse');
        } else {
            setFlexDirection('flex-column');
        }
    }, [isScreenSm, isScreenMd, isScreenLg, isScreenXl]);

    return (
        <div
            className={classNames(
                'flex',
                styles.cardWrapper,
                isProductPage && styles.productPage,
                flexDirection,
                isScreenLg && !isProductPage && 'justify-content-between',
            )}
        >
            <div className={styles.imageWrapper}>
                <div
                    className={styles.imageWrapper}
                    style={{
                        backgroundImage: `url("${image}")`,
                        backgroundSize: 'cover',
                    }}
                />
                {/*<div className={styles.marksWrapper}>*/}
                {/*    {productMockData[data.mnemoCode]?.marks.map((mark, index) => (*/}
                {/*        <Mark id={`${data.id}-${mark}`} type={mark} key={index} />*/}
                {/*    ))}*/}
                {/*</div>*/}
            </div>
            <div
                className={classNames(styles.contentWrapper, {
                    [styles.contentWrapper__withBigText]: data.mnemoCode === ProductMnemoCodes.CKI,
                })}
            >
                <div className={styles.textWrapper}>
                    <div>
                        <img src={logo} />
                        {data.mnemoCode === ProductMnemoCodes.MARKET_VISION && (
                            <div className={styles.earlyVersion}>ранняя версия</div>
                        )}
                    </div>

                    <p>{data.detail}</p>
                </div>

                {data.status === 'ACTIVE'
? (
                    <Link
                        className={classNames(styles.btn, styles.openBtn)}
                        to={productRoute()[data.mnemoCode]}
                        reloadDocument={productRoute()[data.mnemoCode]?.startsWith('https')}
                    >
                        <span>Открыть</span>
                    </Link>
                )
: (
                    <Button className={classNames(styles.btn, styles.disabledBtn, styles.soonBtn)}>
                        <img src={Lock} />
                        <span>Запуск скоро</span>
                    </Button>
                )}
            </div>
        </div>
    );
};
