import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.scss';
import { ThemeProvider } from '@libs/themes';

import reportWebVitals from './reportWebVitals';

import './configs/i18n';
import { Provider } from 'react-redux';
import { store } from '@store/store';

import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
    <React.StrictMode>
        <ThemeProvider>
            <Provider store={store}>
                <App />
            </Provider>
        </ThemeProvider>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
