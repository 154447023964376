import classNames from 'classnames';
import { CardComponent } from '@modules/PersonalAccount/PersonalAccountPage/components/MainContent/components/CardsBlock/CardComponent';
import { DropdownWrapper } from '@libs/components';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { AppDispatch, RootState } from '@store/store';
import { ReactComponent as PlusIcon } from '@shared/images/svg/plusWhite.svg';
import { getSavedCardsThunk } from '@store/store/thunk/card.thunk';
import { Button } from 'primereact/button';
import { useResize } from '@libs/utils';
import { addSavedCard } from '@libs/services';

import styles from './styles.module.scss';

export const CardsBlock = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { isScreenSm } = useResize();

    const savedCards = useSelector((state: RootState) => state.card.savedCards);

    useEffect(() => {
        dispatch(getSavedCardsThunk());
    }, []);

    const handleAddCard = async () => {
        try {
            const { data } = await addSavedCard();

            if (data) {
                window.location.href = `${window._env_.REACT_APP_MONETA_API}?operationId=${data.operationId}&paymentSystem.limitIds=card&paymentSystem.unitId=card&MNT_INPROGRESS_URL=${window._env_.REACT_APP_DS_URL}/personal-account&MNT_SIGNATURE=${data.signature}`;
            }
        } catch (e) {
            console.error(e);
        }
    };

    const addCartBtn = (
        <Button
            icon={<PlusIcon />}
            className={classNames(
                styles.addCardBtn,
                savedCards && savedCards.length && !isScreenSm && styles.btnPosition,
            )}
            onClick={handleAddCard}
        />
    );

    return (
        <DropdownWrapper title={'Способы оплаты'}>
            <div className={classNames(styles.cardWrapper, 'flex', 'flex-wrap')}>
                {savedCards && savedCards.length
? (
                    <>
                        {savedCards.map((data) => (
                            <CardComponent key={data.id} {...data} />
                        ))}
                        {addCartBtn}
                    </>
                )
: null}
                {(!(savedCards && savedCards.length) || isScreenSm) && (
                    <div className={classNames(styles.addCart, 'flex', 'align-items-center')}>
                        {addCartBtn}
                        <p>Добавить способ оплаты</p>
                    </div>
                )}
            </div>
        </DropdownWrapper>
    );
};
