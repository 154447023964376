import React, { useLayoutEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import { AuthProvider, type TAuthConfig } from 'react-oauth2-code-pkce';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import { loadScript } from '@libs/services';
import { generateState, getUuid } from '@libs/utils';
import { useSelector } from 'react-redux';
import { type RootState } from '@store/store';

import { AppRouter } from './routes';

const configLoadingErrorMessage =
    'Error while fetching global config, the App wil not be rendered. (This is NOT a React error.)';

function App() {
    const productList = useSelector((state: RootState) => state.product.productList);

    useLayoutEffect(() => {
        window.browserIdValue = getUuid();
        loadScript('browser-id.js', '', false);
    }, []);

    const onRefreshTokenExpire = () => {
        window.location.replace('/session-expired');
    };

    const authConfig: TAuthConfig = {
        clientId: window._env_.REACT_APP_CLIENT_ID || '',
        authorizationEndpoint: window._env_.REACT_APP_AUTH_ENDPOINT || '',
        tokenEndpoint: window._env_.REACT_APP_TOKEN_ENDPOINT || '',
        logoutEndpoint: window._env_.REACT_APP_LOGOUT_ENDPOINT || '',
        redirectUri: window._env_.REACT_APP_DS_URL || '',
        preLogin: () => {
            if (!localStorage.getItem('preLoginPath')) {
                localStorage.setItem(
                    'preLoginPath',
                    window.location.pathname === '/personal-account/session-expired'
                        ? `/${window.location.search}`
                        : window.location.pathname,
                );
            }
        },
        postLogin: () => {
            const path =
                localStorage.getItem('preLoginPath') !== '/session-expired'
                    ? localStorage.getItem('preLoginPath') || ''
                    : '';
            localStorage.removeItem('preLoginPath');
            window.location.replace(path);
        },
        logoutRedirect: `${window._env_.REACT_APP_DS_URL}/logout`,
        scope: window._env_.REACT_APP_SCOPE || '',
        onRefreshTokenExpire,
        autoLogin: false,
        state: generateState(),
        extraAuthParameters: {
            nonce: getUuid(),
            response_mode: 'query',
            response_type: 'code',
        },
    };

    const gtmParams = {
        id: 'GTM-TPQVWHKT',
        dataLayerName: 'dataLayer',
    };

    const router = AppRouter();
    const isGTM = () => {
        if (productList.length > 0) {
            if (!window._env_.NODE_ENV || window._env_.NODE_ENV === 'development') {
                return <RouterProvider router={router} />;
            } else {
                return (
                    <GTMProvider state={gtmParams}>
                        <RouterProvider router={router} />
                    </GTMProvider>
                );
            }
        }
    };

    return <AuthProvider authConfig={authConfig}>{isGTM()}</AuthProvider>;
}

export default App;
